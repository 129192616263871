import React, { useEffect, useRef, useState } from 'react';
import './priceBreakUp.css';
import { useResponsive } from 'hooks';
import { useGetBackgroundColor } from "../../../../src/api/index";

const PriceBreakUp = props => {
  const { isDesktop, isMobile } = useResponsive();
  function formatPriceWithCommas(price) {
    // Convert the price to a string
    const priceString = price.toString();
  
    // Split the price into integer and decimal parts (if any)
    const [integerPart, decimalPart = ""] = priceString.split(".");
  
    // Add commas to the integer part
    let formattedIntegerPart = "";
    for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
      if ((count === 3 && i !== 0) || ((count + 1) % 2 === 0 && count > 3)) {
        formattedIntegerPart = "," + formattedIntegerPart;
      }
  
      formattedIntegerPart = integerPart[i] + formattedIntegerPart;
    }
  
    // Combine the formatted parts and return the result
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  }
  const {
    name: productName,
    carat = 22,
    metalColor = "",
    priceBreakdown = {}
  } = props?.productDetails || {};
  console.log(props?.productDetails);
  const {
    metalPrice: metalRate = 0,
    netWeight = 0,
    metalType = "Gold",
    type: stoneType = "",
    totalMakingCharge = 0,
    totalWastageCharge = 0,
    gemstoneDetails = []
  } = priceBreakdown;
  
  const totalMetalPrice = parseFloat(metalRate * netWeight).toFixed(2);
  const totalMakingCharges = parseFloat(totalMakingCharge).toFixed(2);
  const totalWastageCharges = parseFloat(totalWastageCharge).toFixed(2);
  const hasGemstone = gemstoneDetails.length > 0;
  let totalGemstoneCharges = 0;
  gemstoneDetails?.map((gem) => totalGemstoneCharges+=gem?.gemstoneCharges);
  const subTotal = parseFloat(totalMetalPrice) + parseFloat(totalMakingCharges) + parseFloat(totalWastageCharges) + parseFloat(totalGemstoneCharges);
  const tax = parseFloat((subTotal * 3) / 100).toFixed(2);
  const grandTotal = parseFloat(parseInt(subTotal) + parseInt(tax)).toFixed(0);
  // const hasDiamond = gemstoneDetails.some(gem => gem?.name?.toLowerCase() === "diamond");
   
  
  const handleCloseButton = () => {
    props.priceBreakUpModelClose(false);
  };
  const { data } = useGetBackgroundColor({ onLoad: true });
  return (
    <>
      {!isMobile && (<div
        className='relative z-10'
        aria-labelledby='slide-over-title'
        role='dialog'
        aria-modal='true'
      >
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        ></div>
        <div className='fixed inset-0 overflow-hidden w-full'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <div className='pointer-events-auto relative w-screen max-w-md'>
                {/* Title Section */}
                <div className='header rounded-tl-[30px]' style={{backgroundColor : data.color.shade3}}>
                  <div className='title'>{productName}</div>
                  <button
                    type='button'
                    className='close relative cursor-pointer rounded-md text-black'
                    onClick={handleCloseButton}
                  >
                    <span className='absolute -inset-2.5'></span>
                    <span className='sr-only'>Close panel</span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='41'
                      height='41'
                      viewBox='0 0 41 41'
                      fill='none'
                    >
                      <path
                        d='M20.5003 38.8646C10.3699 38.8646 2.13574 30.6304 2.13574 20.5C2.13574 10.3696 10.3699 2.13542 20.5003 2.13542C30.6307 2.13542 38.8649 10.3696 38.8649 20.5C38.8649 30.6304 30.6307 38.8646 20.5003 38.8646ZM20.5003 4.69792C11.7878 4.69792 4.69824 11.7875 4.69824 20.5C4.69824 29.2125 11.7878 36.3021 20.5003 36.3021C29.2128 36.3021 36.3024 29.2125 36.3024 20.5C36.3024 11.7875 29.2128 4.69792 20.5003 4.69792Z'
                        fill='#292D32'
                      />
                      <path
                        d='M15.6657 26.6158C15.3411 26.6158 15.0165 26.4963 14.7602 26.24C14.2648 25.7446 14.2648 24.9246 14.7602 24.4292L24.4294 14.76C24.9248 14.2646 25.7448 14.2646 26.2402 14.76C26.7357 15.2554 26.7357 16.0754 26.2402 16.5708L16.5711 26.24C16.3319 26.4963 15.9902 26.6158 15.6657 26.6158Z'
                        fill='#292D32'
                      />
                      <path
                        d='M25.3348 26.6158C25.0102 26.6158 24.6857 26.4963 24.4294 26.24L14.7602 16.5708C14.2648 16.0754 14.2648 15.2554 14.7602 14.76C15.2557 14.2646 16.0757 14.2646 16.5711 14.76L26.2402 24.4292C26.7357 24.9246 26.7357 25.7446 26.2402 26.24C25.984 26.4963 25.6594 26.6158 25.3348 26.6158Z'
                        fill='#292D32'
                      />
                    </svg>
                  </button>
                </div>
                <div className='flex h-full flex-col bg-white shadow-xl'>
                  <div className='px-3 sm:px-6'>
                    <div className='price-breakup-container'>
                      {/* Price Breakdown Table */}
                      <div className='price-breakup'>
                        <div className='font-bold mb-2'>PRODUCT PRICE BREAKUP</div>
                        <div className='overflow-y-auto max-h-[calc(100vh-130px)]'>
                          <div className='price-row'>
                            <span>COMPONENT</span>
                            <span className='text-center'>RATE</span>
                            <span className='text-center'>WEIGHT</span>
                            <span className='text-center'>VALUE</span>
                          </div>

                          <div className='price-row'>
                            <span className='detailsHeading'>{carat} KT {metalColor}</span>
                            <span  className='details'>₹{formatPriceWithCommas(parseInt(metalRate))}/g</span>
                            <span  className='details'>{formatPriceWithCommas(netWeight)}g</span>
                            <span  className='details'>₹{formatPriceWithCommas(parseInt(totalMetalPrice))}</span>
                          </div>

                          <div className='price-row total'>
                            <span className='text-left'>Total {metalType} Value</span>
                            <span className='details'> - </span>
                            <span className='details'> - </span>
                            <span>₹{formatPriceWithCommas(parseInt(totalMetalPrice ?? 0))}</span>
                          </div>
                          
                          {stoneType.toLowerCase() === "studded" &&
                            gemstoneDetails?.map((gem, index) => {                             
                              if (gem?.name?.toLowerCase() !== "diamond") {
                                return (
                                  <div key={index + 10}>
                                    <div className='price-row'>
                                      <div className='detailsHeading'>{gem?.name}</div>
                                      <div className='details'> - </div>
                                      <div className='details'> - </div> {/* Assuming weight could be a property */}
                                      <div className='details'>₹{parseInt(gem?.gemstoneCharges)}</div>
                                    </div>

                                    <div className='price-row total'>
                                      <span>Total {gem?.name} Value</span>
                                      <span> - </span>
                                      <span> - </span>
                                      <span>₹{formatPriceWithCommas(parseInt(gem?.gemstoneCharges))}</span>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={index + 10}>
                                    <div className='price-row'>
                                      <span>{gem?.dri.split(",").reverse()[0]} {gem?.dri.split(",")[0]}-{gem?.totalCount} Nos.</span>
                                      <span> - </span>
                                      <span>{parseFloat(gem?.diamondWeigth).toFixed(3)}ct</span>
                                      <span>₹{formatPriceWithCommas(parseInt(gem?.gemstoneCharges))}</span>
                                    </div>

                                    <div className='price-row total'>
                                      <span>Total Diamond Value</span>
                                      <span> - </span>
                                      <span> - </span>
                                      <span>₹{formatPriceWithCommas(parseInt(gem?.gemstoneCharges))}</span>
                                    </div>
                                  </div>
                                );
                              }
                            })
                          }

                        <hr className='hr-divider'></hr>
                          
                          <div className='price-row'>
                            <div className='detailsHeading'>Making Charges</div>
                            <div className='details'> - </div>
                            <div className='details'> - </div>
                            <div className='details'>₹{formatPriceWithCommas(parseInt(totalMakingCharges))}</div>
                          </div>


                          <div className='price-row'>
                            <div className='detailsHeading'>Wastage Charges</div>
                            <div className='details'> - </div>
                            <div className='details'> - </div>
                            <div className='details'>₹{formatPriceWithCommas(parseInt(totalWastageCharges))}</div>
                          </div>

                          <div className='price-row total'>
                            <span className='text-left'>Subtotal</span>
                            <span></span>
                            <span></span>
                            <span>₹{formatPriceWithCommas(parseInt(subTotal))}</span>
                          </div>
                        <hr className='hr-divider'></hr>
                          <div className='price-row'>
                            <div className='detailsHeading'>Tax</div>
                            <div className='details'> - </div>
                            <div className='details'> - </div>
                            <div className='details'>₹{formatPriceWithCommas(parseInt(tax))}</div>
                          </div>

                          <div className='price-row'>
                            <span>Grand Total</span>
                            <span></span>
                            <span></span>
                            <span className='text-center'>₹{formatPriceWithCommas(parseInt(grandTotal))}</span>
                          </div>
                          {hasGemstone ?
                            gemstoneDetails.map((gem, index) => {
                              if (gem?.name?.toLowerCase() !== "diamond") {
                                return (
                                  <div key={index+20}>
                                   <hr className='hr-divider'></hr>
                                    <div className='font-bold'>GEMSTONE DETAILS</div>
                                    <div className='price-row'>
                                      <span>GEMSTONE TYPE</span>
                                      <span>COUNT</span>
                                      <span>WEIGHT</span>
                                    </div>

                                    <div className='price-row'>
                                      <div className='detailsHeading'>{gem?.name}</div>                                   
                                      <div className='details'>{gem?.totalCount}</div>
                                      <div className='details'>{parseFloat(gem?.diamondWeigth).toFixed(3)} ct</div>
                                    </div>
                                  </div>
                                );
                              }else if (gem?.name?.toLowerCase() === "diamond"){
                                return (
                                  <div key={index}>
                                   <hr className='hr-divider'></hr>
                                    <div className='font-bold'>DIAMOND DETAILS</div>

                                    <div className='price-row'>
                                      <span>DIAMOND TYPE</span>
                                      {/* <span>SETTING</span> */}
                                      <span>COUNT</span>
                                      <span>WEIGHT</span>
                                    </div>

                                    <div className='price-row'>
                                      <div className='detailsHeading'>{gem?.dri.split(",").reverse()[0]}</div>
                                      {/* <div>Prong</div> */}
                                      <div className='details'>{gem?.totalCount}</div>
                                      <div className='details'>{parseFloat(gem?.diamondWeigth).toFixed(3)} ct</div>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })
                            : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      {isMobile && (
        <div
          class='relative z-10'
          aria-labelledby='modal-title'
          role='dialog'
          aria-modal='true'
        >
          <div
            class='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
            aria-hidden='true'
          ></div>

          <div class='fixed inset-0 z-10 w-screen overflow-y-auto mt-[35%]'>
            <div class='flex min-h-full items-end justify-end p-4 text-center sm:p-0'>
              <div class='relative transform overflow-hidden rounded-t-[30px] bg-white text-left shadow-xl transition-all sm:my-0 sm:w-full sm:max-w-lg'>
                <div class='m:pb-4 bg-white pb-4'>
                  <div class=''>
                  <div className='header' style={{backgroundColor : data.color.shade3}}>
                  <div className='title text-center' style={{flex: 1, textAlign: 'center'}}>{productName}</div>
                  <button
                    type='button'
                    className='close relative cursor-pointer rounded-md text-black'
                    onClick={handleCloseButton}
                  >
                    <span className='absolute -inset-2.5'></span>
                    <span className='sr-only'>Close panel</span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='41'
                      height='41'
                      viewBox='0 0 41 41'
                      fill='none'
                    >
                      <path
                        d='M20.5003 38.8646C10.3699 38.8646 2.13574 30.6304 2.13574 20.5C2.13574 10.3696 10.3699 2.13542 20.5003 2.13542C30.6307 2.13542 38.8649 10.3696 38.8649 20.5C38.8649 30.6304 30.6307 38.8646 20.5003 38.8646ZM20.5003 4.69792C11.7878 4.69792 4.69824 11.7875 4.69824 20.5C4.69824 29.2125 11.7878 36.3021 20.5003 36.3021C29.2128 36.3021 36.3024 29.2125 36.3024 20.5C36.3024 11.7875 29.2128 4.69792 20.5003 4.69792Z'
                        fill='#292D32'
                      />
                      <path
                        d='M15.6657 26.6158C15.3411 26.6158 15.0165 26.4963 14.7602 26.24C14.2648 25.7446 14.2648 24.9246 14.7602 24.4292L24.4294 14.76C24.9248 14.2646 25.7448 14.2646 26.2402 14.76C26.7357 15.2554 26.7357 16.0754 26.2402 16.5708L16.5711 26.24C16.3319 26.4963 15.9902 26.6158 15.6657 26.6158Z'
                        fill='#292D32'
                      />
                      <path
                        d='M25.3348 26.6158C25.0102 26.6158 24.6857 26.4963 24.4294 26.24L14.7602 16.5708C14.2648 16.0754 14.2648 15.2554 14.7602 14.76C15.2557 14.2646 16.0757 14.2646 16.5711 14.76L26.2402 24.4292C26.7357 24.9246 26.7357 25.7446 26.2402 26.24C25.984 26.4963 25.6594 26.6158 25.3348 26.6158Z'
                        fill='#292D32'
                      />
                    </svg>
                  </button>
                </div>
                  </div>
                </div>
                <div className='px-3 sm:px-6 mb-5'>
                    <div className='price-breakup-container'>
                      {/* Price Breakdown Table */}
                      <div className='price-breakup'>
                        <div className='font-bold mb-2'>PRODUCT PRICE BREAKUP</div>

                        <div className='price-row text-center'>
                          <span>COMPONENT</span>
                          <span className='text-center'>RATE</span>
                          <span className='text-center'>WEIGHT</span>
                          <span className='text-center'>VALUE</span>
                        </div>

                        <div className='price-row'>
                            <span className='detailsHeading'>{carat} KT {metalColor}</span>
                            <span className='details'>₹{formatPriceWithCommas(parseInt(metalRate))}/g</span>
                            <span className='details'>{formatPriceWithCommas(netWeight)}g</span>
                            <span className='details'>₹{formatPriceWithCommas(parseInt(totalMetalPrice))}</span>
                          </div>

                          <div className='price-row total'>
                            <span className='text-left'>Total {metalType} Value</span>
                            <span className='details'> - </span>
                            <span className='details'> - </span>
                            <span>₹{formatPriceWithCommas(parseInt(totalMetalPrice ?? 0))}</span>
                          </div>
                          
                          {stoneType.toLowerCase() === "studded" &&
                            gemstoneDetails?.map((gem, index) => {                             
                              if (gem?.name?.toLowerCase() !== "diamond") {
                                return (
                                  <div key={index + 10}>
                                    <div className='price-row'>
                                      <div className='detailsHeading'>{gem?.name}</div>
                                      <div className='details'> - </div>
                                      <div className='details'> - </div> {/* Assuming weight could be a property */}
                                      <div className='details'>₹{parseInt(gem?.gemstoneCharges)}</div>
                                    </div>

                                    <div className='price-row total'>
                                      <span>Total {gem?.name} Value</span>
                                      <span> - </span>
                                      <span> - </span>
                                      <span>₹{formatPriceWithCommas(parseInt(gem?.gemstoneCharges))}</span>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={index + 10}>
                                    <div className='price-row'>
                                      <span>{gem?.dri.split(",").reverse()[0]} {gem?.dri.split(",")[0]}-{gem?.totalCount} Nos.</span>
                                      <span> - </span>
                                      <span>{parseFloat(gem?.diamondWeigth).toFixed(3)}ct</span>
                                      <span>₹{formatPriceWithCommas(parseInt(gem?.gemstoneCharges))}</span>
                                    </div>

                                    <div className='price-row total'>
                                      <span>Total Diamond Value</span>
                                      <span> - </span>
                                      <span> - </span>
                                      <span>₹{formatPriceWithCommas(parseInt(gem?.gemstoneCharges))}</span>
                                    </div>
                                  </div>
                                );
                              }
                            })
                          }

                        <hr className='hr-divider'></hr>

                                       
                        <div className='price-row'>
                            <div className='detailsHeading'>Making Charges</div>
                            <div className='details'> - </div>
                            <div className='details'> - </div>
                            <div className='details'>₹{formatPriceWithCommas(parseInt(totalMakingCharges))}</div>
                          </div>


                          <div className='price-row'>
                            <div className='detailsHeading'>Wastage Charges</div>
                            <div className='details'> - </div>
                            <div className='details'> - </div>
                            <div className='details'>₹{formatPriceWithCommas(parseInt(totalWastageCharges))}</div>
                          </div>

                          <div className='price-row total'>
                            <span className='text-left'>Subtotal</span>
                            <span></span>
                            <span></span>
                            <span>₹{formatPriceWithCommas(parseInt(subTotal))}</span>
                          </div>

                        <hr className='hr-divider'></hr>
                        <div className='price-row'>
                          <div className='detailsHeading'>Tax</div>
                          <div className='details'>-</div>
                          <div className='details'>-</div>
                          <div className='details'>₹{formatPriceWithCommas(parseInt(tax))}</div>
                        </div>

                        <div className='price-row'>
                          <span>Grand Total</span>
                          <span></span>
                          <span></span>
                          <span className='text-center'>₹{formatPriceWithCommas(parseInt(grandTotal))}</span>
                          </div>
                          {hasGemstone ?
                            gemstoneDetails.map((gem, index) => {
                              if (gem?.name?.toLowerCase() !== "diamond") {
                                return (
                                  <div key={index+20}>
                                   <hr className='hr-divider'></hr>
                                    <div className='font-bold'>GEMSTONE DETAILS</div>
                                    <div className='price-row'>
                                      <span>GEMSTONE TYPE</span>
                                      <span>COUNT</span>
                                      <span>WEIGHT</span>
                                    </div>

                                    <div className='price-row'>
                                      <div className='detailsHeading'>{gem?.name}</div>                                   
                                      <div className='details'>{gem?.totalCount}</div>
                                      <div className='details'>{parseFloat(gem?.diamondWeigth).toFixed(3)} ct</div>
                                    </div>
                                  </div>
                                );
                              }else if (gem?.name?.toLowerCase() === "diamond"){
                                return (
                                  <div key={index}>
                                   <hr className='hr-divider'></hr>
                                    <div className='font-bold'>DIAMOND DETAILS</div>

                                    <div className='price-row'>
                                      <span>DIAMOND TYPE</span>
                                      {/* <span>SETTING</span> */}
                                      <span>COUNT</span>
                                      <span>WEIGHT</span>
                                    </div>

                                    <div className='price-row'>
                                      <div className='detailsHeading'>{gem?.dri.split(",").reverse()[0]}</div>
                                      {/* <div>Prong</div> */}
                                      <div className='details'>{gem?.totalCount}</div>
                                      <div className='details'>{parseFloat(gem?.diamondWeigth).toFixed(3)} ct</div>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })
                            : null
                          }
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PriceBreakUp;
