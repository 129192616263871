import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css'; // Import Swiper CSS
import { Link } from 'react-router-dom';
import { useGetBackgroundColor } from "../../../src/api/index";

const CollectionItem = ({ id, imgSrc, label }) => {
  return (
    <Link
      to={`${process.env.REACT_APP_PRODUCT_ROUTE}?${process.env.REACT_APP_COLLECTION_PRODUCT_QUERY_ROUTE}=${id}`}
      className='border-b border-black text-[20px] font-semibold md:text-[20px] sm:text-[15px]'
    >
      <div className='collection--wrapper overflow-hidden rounded-[20px]'>
        <img
          src={imgSrc}
          alt={label}
          // width={345}
          // height={433}
          className='w-full h-[367px] sm:h-[304px] '
        />
        <div>
          <p className='bg-[#D9D9D9] py-[14px] text-center font-inter text-[20px] sm:text-[15px] font-[600] text-[#0E1150] sm:h-[56px] h-[68px]'>
            {label}
          </p>
        </div>
      </div>
    </Link>
  );
};

const ShopByCollection = ({ data:dataCollection }) => { 
  const { data } = useGetBackgroundColor({ onLoad: true });
  const prevRef = useRef(null);
  const nextRef = useRef(null); 
  const swiperRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 640); // "sm" breakpoint in Tailwind is 640px
    };

    // Check initial screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      // Bind custom navigation buttons
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.update();
    }
  }, []);
  return (
    <section className='py-[14px]' style={{backgroundColor : data.color.shade1}}>
      <div>
      {/* Text Section */}
      <div className='text-white text-center'>
            <h4 className='font-serif mb-4 text-[30px] font-black leading-[50px] sm:text-[14px] sm:leading-[20px] sm:mb-2'>
              SHOP BY{' '}
              <span className='block text-[65px] font-medium sm:text-[30px] sm:mt-[2px]'>
                Collection
              </span>
            </h4>
            <p className='max-w-[500px] text-[#f5f5f5] mx-auto text-[16px] font-semibold font-inter sm:text-[15px] sm:pt-[10px]'>
              Check out the curated collection of rings & jewelry, selected to
              suit that right occasion.
            </p>
          </div> 
        <div className={`mt-3 mx-auto mb-3 sm:mb-[-65px]`}>        
          {/* Swiper Section */}
          <div className={isDesktop ? "relative":""}>
            <Swiper
              ref={swiperRef}
              className='w-full'
              loop={dataCollection?.length >= 5 ? true : false}
              spaceBetween={15}
              slidesPerView={1.2}
              modules={[Navigation]}             
              centeredSlides={true} // Ensure slides are always centered
              initialSlide={dataCollection?.length >= 4 ? Math.floor(dataCollection?.length / 2):3} // Start with the middle slide
              breakpoints={{
                424: { slidesPerView: 1.5, centeredSlides: true, initialSlide:3 }, // Adjust for mobile view
                768: { slidesPerView: 1, centeredSlides: true },
                1440: { slidesPerView: 6, centeredSlides: true },
              }}
              // breakpoints={{
              //   640: { slidesPerView: 1.5, centeredSlides: true }, // Adjust for mobile view
              //   768: { slidesPerView: 2 },
              //   1024: { slidesPerView: 3 },
              // }}
              // loop={true} // Enable loop for infinite scrolling
              onSwiper={(swiper) => {
                // Store swiper instance for later use
                swiperRef.current = swiper;
              }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
            >
              {dataCollection?.map((collection, index) => {
                const collectionId = collection?.node?.id || null;
                const imgSrc = collection?.node?.backgroundImage?.url || '';
                const name = collection?.node?.name || `Collection #${index}`;
                return (
                  <SwiperSlide
                    key={index}
                    className='justify-center items-center contents --swiper-slide-custom-collection'
                  >
                    <CollectionItem id={collectionId} imgSrc={imgSrc} label={name} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {isDesktop &&(<> <button  ref={prevRef} className='absolute left-1 top-1/2 z-10 -translate-y-1/2 transform'>
              <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
              <circle cx="27" cy="27" r="27" fill="#D9D9D9" fill-opacity="0.25"/>
              <path d="M29.7768 38.125L19.8125 26.5L29.7768 14.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </button>
              <button ref={nextRef} className='absolute right-1 top-1/2 z-10 -translate-y-1/2 transform'>
              <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
              <circle cx="27" cy="27" r="27" fill="#D9D9D9" fill-opacity="0.25"/>
              <path d="M24.2232 39.125L34.1875 27.5L24.2232 15.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </button>
            </>)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopByCollection;
