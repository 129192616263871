import React, { useState, useRef, useEffect } from 'react';
import { useSendAndValidateOtp } from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import useHistoryTracker from '../../../hooks/useHistoryTracker'; // Import the custom hook
const MobileOTP = ({phone}) => {
    const [values, setValues] = useState(Array(6).fill(''));
    const inputsRef = useRef([]);
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(false);
    const { verifyOtp, error, success, resendOtp } = useSendAndValidateOtp();
    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve saved timer from localStorage
        const savedTimer = localStorage.getItem('otpTimer');
        const savedTimestamp = localStorage.getItem('otpTimestamp');
        
        if (savedTimer && savedTimestamp) {
            const timeElapsed = Math.floor((Date.now() - parseInt(savedTimestamp)) / 1000);
            const remainingTime = parseInt(savedTimer) - timeElapsed;
            
            if (remainingTime > 0) {
                setTimer(remainingTime);
            } else {
                setCanResend(true);
            }
        } else {
            setCanResend(true);
        }
    }, []);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            localStorage.setItem('otpTimer', timer);
            localStorage.setItem('otpTimestamp', Date.now().toString());

            return () => clearInterval(interval);
        } else {
            setCanResend(true);
            localStorage.removeItem('otpTimer');
            localStorage.removeItem('otpTimestamp');
        }
    }, [timer]);

    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value) || value === '') {
            const newValues = [...values];
            newValues[index] = value;
            setValues(newValues);

            if (value && index < values.length - 1) {
                inputsRef.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && values[index] === '') {
            if (index > 0) {
                inputsRef.current[index - 1].focus();
            }
        }
    };

    const handleFocus = (e) => {
        e.target.select();
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        if (/^\d{6}$/.test(pastedText)) {
            const digits = pastedText.split('');
            setValues(digits);
            inputsRef.current[digits.length - 1].focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const otp = values.join('');
        phone = "+91"+phone;
        verifyOtp({otp,phone});    
    };

    const handleResend = () => {
        if (canResend) {
            resendOtp(); 
            setTimer(60); 
            setCanResend(false);
        }
    };
    const goBack = ()=>{navigate('/');}
    return (
        <div className="relative">
            <div className="overlay bg-[#0B0B0BA3] h-full w-full absolute top-0 left-0 z-10" />
            <div className="bg-white rounded-t-[30px] py-[35px] px-[30px] w-full max-w-[400px] mx-auto font-['Inter'] z-20 fixed bottom-0 left-0 right-0">
                <div className="relative">
                    <div
                        onClick={goBack} // Use the goBack function
                        className="flex justify-center items-center h-[54px] w-[54px] bg-[#D9D9D940] rounded-full absolute top-[-95px] right-[0px]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22" fill="none">
                            <path d="M17.875 17.875L11 11M11 11L4.125 4.125M11 11L17.875 4.125M11 11L4.125 17.875" stroke="white" strokeWidth={2} strokeLinecap="round" />
                        </svg>
                    </div>
                    <p className="text-[20px] font-bold mb-[18px] text-center">Enter OTP</p>
                    <form onSubmit={handleSubmit}>
                        <div className="flex items-center justify-center gap-2 mb-[18px]">
                            {values.map((value, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    value={value}
                                    onChange={(e) => handleInputChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    onFocus={handleFocus}
                                    onPaste={handlePaste}
                                    ref={(el) => inputsRef.current[index] = el}
                                    className="h-[30px] w-[30px] text-center text-[18px] font-extrabold text-slate-900 bg-slate-200 border border-transparent hover:border-slate-200 appearance-none rounded-lg outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                                    pattern="\d*"
                                    maxLength="1"
                                />
                            ))}
                        </div>
                        <div className="flex gap-2 mb-[18px] mt-[18px] justify-center text-sm">
                            <p>Code is sent to (+91){phone?.replace("91","")}</p>
                            <span className="h-[15px] w-[15px]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" fill="none" onClick={() => window.history.back()}>
                                    <path d="M3.46249 12.2C3.08124 12.2 2.72499 12.0687 2.46874 11.825C2.14374 11.5187 1.98749 11.0562 2.04374 10.5562L2.27499 8.53122C2.31874 8.14997 2.54999 7.64372 2.81874 7.36872L7.94999 1.93747C9.23124 0.58122 10.5687 0.54372 11.925 1.82497C13.2812 3.10622 13.3187 4.44372 12.0375 5.79997L6.90624 11.2312C6.64374 11.5125 6.15624 11.775 5.77499 11.8375L3.76249 12.1812C3.65624 12.1875 3.56249 12.2 3.46249 12.2ZM9.95624 1.81872C9.47499 1.81872 9.05624 2.11872 8.63124 2.56872L3.49999 8.00622C3.37499 8.13747 3.23124 8.44997 3.20624 8.63122L2.97499 10.6562C2.94999 10.8625 2.99999 11.0312 3.11249 11.1375C3.22499 11.2437 3.39374 11.2812 3.59999 11.25L5.61249 10.9062C5.79374 10.875 6.09374 10.7125 6.21874 10.5812L11.35 5.14997C12.125 4.32497 12.4062 3.56247 11.275 2.49997C10.775 2.01872 10.3437 1.81872 9.95624 1.81872Z" fill="#848484" />
                                    <path d="M10.8375 6.84378C10.825 6.84378 10.8062 6.84378 10.7937 6.84378C8.84375 6.65003 7.275 5.16878 6.975 3.23128C6.9375 2.97503 7.1125 2.73753 7.36875 2.69378C7.625 2.65628 7.8625 2.83128 7.90625 3.08753C8.14375 4.60003 9.36875 5.76253 10.8937 5.91253C11.15 5.93753 11.3375 6.16878 11.3125 6.42503C11.2812 6.66253 11.075 6.84378 10.8375 6.84378Z" fill="#848484" />
                                    <path d="M13.125 14.2188H1.875C1.61875 14.2188 1.40625 14.0063 1.40625 13.75C1.40625 13.4938 1.61875 13.2812 1.875 13.2812H13.125C13.3813 13.2812 13.5938 13.4938 13.5938 13.75C13.5938 14.0063 13.3813 14.2188 13.125 14.2188Z" fill="#848484" />
                                </svg>
                            </span>
                        </div>
                        <div className="mx-auto mb-[34px]">
                            <button
                                type="submit"
                                className="block w-full text-black text-[18px] font-bold py-[14px] bg-[#D9D9D9] rounded-[25px] flex justify-center focus:bg-black focus:text-white focus:outline-none transition-colors duration-150"
                            >
                                Verify
                            </button>
                        </div>
                        <div className="text-sm text-slate-500 mt-4">
                            Didn't receive code?{' '}
                            <button
                                className={`font-medium ${canResend ? 'text-indigo-500 hover:text-indigo-600' : 'text-gray-400 cursor-not-allowed'}`}
                                onClick={handleResend}
                                disabled={!canResend}
                            >
                                Resend
                            </button>
                        </div>
                        {error && <p className="text-red-500 text-[14px] pt-4">{error.message}</p>}
                        {success && <p className="text-green-500 text-[14px] pt-4">{success.message}</p>}
                        {timer > 0 && <p className="text-sm text-slate-500 mt-4">Resend available in {timer}s</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default MobileOTP;
